
import React, { useState } from 'react';
import { useLocation } from "react-router-dom";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-regular-svg-icons';
import { faEdit, faCaretRight, faSpinner } from '@fortawesome/free-solid-svg-icons';
import Table from 'react-bootstrap/Table';
import Select from 'react-select'
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Button from 'react-bootstrap/Button';
import styled from 'styled-components';

import awsconfig from '../../aws-exports.js';
import { Amplify, API } from 'aws-amplify';

import { StandardPageContainer } from '../../components/common/containers.js'
import { backgroundSecondaryStyle, typewriterPaddingStyle } from '../../components/common/styledCommon.js'
import TypeWriter from '../../components/typewriter/index.js'
import courses from '../../data/courses.json'

// Amplify init for talking to backend
Amplify.configure(awsconfig);
API.configure(awsconfig);

function getAvailableCourses() {
    let options = []
    courses.forEach((c) => {
        if (c.available) {
            options.push({
                value: c.key,
                label: c.name,
                fee: c.price,
                duration: c.duration,
                start_date : c.start_date
            })
        }
    });
    return options
}

function Register() {
    //----------------
    // state variables
    //----------------
    let blank_registration = {
        course_key: "",
        course_name: "",
        course_fee: "",
        course_duration: "",
        course_start_date: "",
        first_name: "",
        last_name: "",
        email: "",
        mobile: "",
        age_group: "",
        additional_comments: "",
        market_media: "",
        referral: "",
        early_bird: false
    }

    let age_groups = {
        radio_age_younger: "15-21 years",
        radio_age_older: "Over 21 years"
    }

    let market_media = {
        radio_market_media_linkedin: "LinkedIn",
        radio_market_media_facebook: "Facebook",
        radio_market_media_instagram: "Instagram",
        radio_market_media_friend: "Referral from friend or colleague",
        radio_market_media_parish: "Parish of Narre Warren",
        radio_market_media_other: "Other"
    }

    // url state
    const { state } = useLocation();
    if (state) {
        if (state.course !== undefined) {
            blank_registration.course_name = state.course.name
            blank_registration.course_key = state.course.key
            blank_registration.course_fee = state.course.price
            blank_registration.course_duration = state.course.duration
            blank_registration.course_start_date = state.course.start_date
        }
    }

    // data state
    const [registration, setRegistration] = useState(blank_registration);
    const [data, setData] = useState([]);

    // visibility state
    const [showForm, setShowForm] = useState(true);
    const [showConfirm, setShowConfirm] = useState(false);
    const [showLoading, setShowLoading] = useState(false);
    const [showThankyou, setShowThankyou] = useState(false);

    //----------------
    // other variables
    //----------------

    const available_courses = getAvailableCourses();

    //----------------
    // handlers
    //----------------

    const handleChange = (e) => {
        let reg_update = { ...registration };
        if (e.target === undefined) {
            reg_update.course_key = e.value
            reg_update.course_name = e.label
            reg_update.course_fee = e.fee
            reg_update.course_duration = e.duration
            reg_update.course_start_date = e.start_date
        } else if (e.target.name === "age_group") {
            reg_update.age_group = age_groups[e.target.id]
        } else if (e.target.name === "market_media") {
            reg_update.market_media = market_media[e.target.id]
        } else if (e.target.name === "early_bird") {
            reg_update.early_bird = !reg_update.early_bird
        } else {
            e.preventDefault();
            if (Object.keys(reg_update).includes(e.target.id)) {
                reg_update[e.target.id] = e.target.value;
            }
        }
        setRegistration(reg_update);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setData([
            { "field": "Course Details", "value": registration.course_name + " (" + registration.course_start_date + ")" },
            { "field": "Course Fee", "value": "A$" + registration.course_fee.toFixed(2) },
            { "field": "Student Name", "value": registration.first_name + " " + registration.last_name },
            { "field": "Student Email", "value": registration.email },
            { "field": "Student Mobile", "value": registration.mobile },
            { "field": "Student Age Group", "value": registration.age_group },
            { "field": "Student Comments", "value": registration.additional_comments },
            { "field": "Referral Email", "value": registration.referral === "" || registration.market_media !== "Referral from friend or colleague" ? "Not Applicable" : registration.referral },
            { "field": "Early-bird Invoice", "value": registration.early_bird ? "Yes" : "No" }
        ]);
        setShowConfirm(true);
        setShowForm(false);
    }

    const handleRegistration = () => {
        // send registration email
        setShowLoading(true);
        API.post("SeedCodeAPI", "/register", { body: registration }
        ).then(res => {
            console.log(res)
            setShowThankyou(true);
        }).catch(res => {
            console.log(res);
        })
    }

    //----------------
    // main
    //----------------

    return <StandardPageContainer style={backgroundSecondaryStyle}>
        <div style={typewriterPaddingStyle}>
            <TypeWriter text="Registration"></TypeWriter>
        </div>
        <br></br>
        <RegistrationContainer>
            {showForm &&
                <Form style={{ textAlign: "left" }} onSubmit={handleSubmit}>
                    <h5>Course</h5>
                    <Select
                        id="course_key"
                        onChange={handleChange}
                        defaultValue={{
                            value: registration.course_key,
                            label: registration.course_name,
                            fee: registration.course_fee,
                            duration: registration.course_duration,
                            start_date: registration.course_start_date
                        }}
                        classNamePrefix="custom_drop_down"
                        options={available_courses} />
                    <br></br>
                    <h5>Student</h5>
                    <Row className="g-2">
                        <Col md>
                            <FloatingLabel label="First Name" className="mb-3">
                                <Form.Control
                                    id="first_name"
                                    placeholder="First name"
                                    value={registration.first_name}
                                    type="text"
                                    onChange={handleChange}
                                    required
                                />
                            </FloatingLabel>
                        </Col>
                        <Col md>
                            <FloatingLabel label="Last Name" className="mb-3">
                                <Form.Control
                                    id="last_name"
                                    placeholder="Last name"
                                    value={registration.last_name}
                                    type="text"
                                    onChange={handleChange}
                                    required
                                />
                            </FloatingLabel>
                        </Col>
                    </Row>
                    <Row className="g-2">
                        <Col md>
                            <FloatingLabel label="Email" className="mb-3">
                                <Form.Control
                                    id="email"
                                    placeholder="Email"
                                    value={registration.email}
                                    type="email"
                                    onChange={handleChange}
                                    required
                                />
                            </FloatingLabel>
                        </Col>
                        <Col md>
                            <FloatingLabel label="Mobile" className="mb-3">
                                <Form.Control
                                    placeholder="Mobile"
                                    id="mobile"
                                    value={registration.mobile}
                                    type="tel"
                                    pattern=".{10}"
                                    title='10-digit number'
                                    onChange={handleChange}
                                    required
                                />
                            </FloatingLabel>
                        </Col>
                    </Row>
                    <Row className="g-2">
                        <FloatingLabel>What is your age group?</FloatingLabel>
                        <div key="age_group-radio" className="mb-3" >
                            <Form.Check
                                type="radio"
                                name="age_group"
                                id="radio_age_younger"
                                checked={registration.age_group === age_groups.radio_age_younger}
                                label={age_groups.radio_age_younger}
                                onChange={handleChange}
                                required
                            />
                            <Form.Check
                                type="radio"
                                name="age_group"
                                id="radio_age_older"
                                checked={registration.age_group === age_groups.radio_age_older}
                                label={age_groups.radio_age_older}
                                onChange={handleChange}
                                required
                            />
                        </div>
                    </Row>
                    <Row className="g-2">
                        <FloatingLabel>Why did you choose this course?</FloatingLabel>
                        <Form.Control
                            id="additional_comments"
                            as="textarea" rows={3}
                            onChange={handleChange}
                            value={registration.additional_comments}
                            placeholder="Please explain why you are interested in this course and how you plan to use coding skills."
                            required
                        />
                    </Row>
                    <br></br>
                    <Row className="g-2">
                        <FloatingLabel>How did you hear about us?</FloatingLabel>
                        <div key="market_media-radio" className="mb-3" >
                            {
                                Object.keys(market_media).map((m, key) => (
                                    <Form.Check
                                        key={key}
                                        type="radio"
                                        name="market_media"
                                        id={m}
                                        checked={registration.market_media === market_media[m]}
                                        label={market_media[m]}
                                        onChange={handleChange}
                                        required
                                    />
                                ))
                            }
                        </div>
                        {registration.market_media === market_media["radio_market_media_friend"] &&
                            <> <FloatingLabel>"Refer-a-friend" discount is available when both of you register:</FloatingLabel>
                                <Form.Control
                                    id="referral"
                                    type="email" rows={3}
                                    onChange={handleChange}
                                    value={registration.referral}
                                    placeholder="Enter email address of friend or colleage so we can apply a 10% discount for both of you."
                                    required
                                /></>
                        }
                    </Row>
                    <br></br>
                    <Row>
                        {!(registration.market_media === market_media["radio_market_media_parish"] &&
                            registration.course_key === "python") &&
                            <FloatingLabel>Want to pay early and recieve a 10% discount?
                                <Form.Check
                                    type="checkbox"
                                    name="early_bird"
                                    id="checkbox_early_bird"
                                    checked={registration.early_bird}
                                    label="Send invoice now for the 'Early-bird' discount."
                                    onChange={handleChange}
                                />
                            </FloatingLabel>
                        }
                    </Row>
                    <br></br>
                    <div className="col text-center">
                        <Button type="submit" size="m" >Review Details
                            &nbsp;<FontAwesomeIcon icon={faCaretRight} size='1x' />
                        </Button>
                    </div>
                </Form>
            }
            {showConfirm && <ConfirmationContainer>
                {
                    <Button size="sm" onClick={() => { setShowConfirm(false); setShowForm(true); }} disabled={showLoading || showThankyou}>
                        <FontAwesomeIcon icon={faEdit} size='1x' /> Edit Registration
                    </Button>
                }
                <br /><br />
                <Table bordered size="sm" style={{ width: "80%", margin: "auto" }}>
                    <tbody>
                        {data.map((f, key) => (
                            <tr key={key}>
                                <td style={{ textAlign: "left", width: "40%" }}>{f.field}</td>
                                <td style={{ textAlign: "left" }} >{f.value}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
                <br></br>
                {!showLoading &&
                    <div className="col text-center">
                        <Button size="m" onClick={handleRegistration}>Confirm Registration
                            &nbsp;<FontAwesomeIcon icon={faCircleCheck} size='1x' />
                        </Button>
                    </div>
                }
                {showLoading && !showThankyou && <FontAwesomeIcon icon={faSpinner} size='2x' className="fa-spin" />}
                {showThankyou &&
                    <>
                        <p>
                            Registration processed successfully  &nbsp;
                            <FontAwesomeIcon icon={faCircleCheck} color="green" size='1x' />
                            <br></br>
                            <i>Please save this page as a record of your registration. A student support officer will get in touch with you within 2 business days.</i>
                            <br></br>
                            <i> If there are any urgent enquiries please email admin@seedcode.com.au </i>
                        </p>
                    </>
                }
            </ConfirmationContainer>
            }
        </RegistrationContainer>
    </StandardPageContainer >
}

const RegistrationContainer = styled.div`
    label {
        color:grey;
    }
    background-color:white;
    padding: 30px;
    border-radius: 10px;
`
const ConfirmationContainer = styled.div`
    background-color:white;
`



export default Register;