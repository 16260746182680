import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Badge from 'react-bootstrap/Badge'
import Accordion from 'react-bootstrap/Accordion';
import Button from 'react-bootstrap/Button';
import { LinkContainer } from 'react-router-bootstrap'
import { StandardPageContainer } from '../../components/common/containers.js'
import {
    LineBreak, CardImage,
    backgroundSecondaryStyle, typewriterPaddingStyle,
    accordionBodyTextStyle, accordionHeaderTextStyle
} from '../../components/common/styledCommon.js'
import { difficultyClass } from '../../components/common/mappings.js'
import courses from '../../data/courses.json'
import TypeWriter from '../../components/typewriter/index.js'



function Courses() {
    return <StandardPageContainer style={backgroundSecondaryStyle}>
        <div style={typewriterPaddingStyle}>
            <TypeWriter text="Upcoming Courses"></TypeWriter>
        </div>
        <Accordion style={{
            "marginLeft": "5%", "marginRight": "5%", "marginBottom": "5%",
        }} >
            {courses.map((i, key) => (
                <Accordion.Item eventKey={key} key={key} >
                    <Accordion.Header>
                        <CardImage src={"/courses/" + i.key + ".jpg"} />
                        <div style={accordionHeaderTextStyle}> 
                            <strong>{" [" + i.start_date + "] "} </strong> - {i.name}
                            <LineBreak />
                            <Badge pill bg={difficultyClass[i.level]["class"]}>
                                <FontAwesomeIcon icon={difficultyClass[i.level]["icon"]} />
                                &nbsp;{i.level}
                            </Badge>
                            <LineBreak />
                        </div>
                    </Accordion.Header>
                    <Accordion.Body style={accordionBodyTextStyle}>
                        {i.modules.length > 0 &&
                            <div >
                                <div style={{ padding: "5px" }}>
                                    {i.description}
                                </div>
                                <div style={{ padding: "5px" }}>
                                    <b>Timetable:</b> {i.timetable === "" ? "Course schedule not yet available." : i.timetable + " starting " + i.start_date + "."}
                                </div>
                                <div style={{ padding: "5px" }}>
                                    <b>Lesson Plan:</b>
                                    <ul >
                                        {i.modules.map((module, module_key) =>
                                            <li key={module_key}>{module.key}{module}</li>
                                        )}
                                    </ul>
                                </div>
                                <div style={{ padding: "5px" }}>
                                    <b>Requirements:</b> {i.requirements}
                                </div>
                                <div style={{ padding: "5px" }}>
                                    <b>Course Price:</b> A${i.price.toFixed(2)} <br></br>
                                    <p style={{ color: "#993399" }}>
                                        Payment is only required by Week 3 of the course so you can try the course for the first two weeks without any cost.
                                    </p>
                                </div>
                                <div style={{ padding: "5px" }}>
                                    <b>Enquiries:</b> admin@seedcode.com.au
                                </div>
                                <br></br>
                                {i.available !== false && <LinkContainer to="/register" state={{ course: i }}>
                                    <Button variant="primary" size="m">Register</Button>
                                </LinkContainer>}
                            </div>
                        }
                        {i.available === false && "Course registration is not yet available. Send us an email if you are interested or have any queries about the course."}
                    </Accordion.Body>
                </Accordion.Item>
            ))}
        </Accordion>
    </StandardPageContainer >
}

export default Courses;